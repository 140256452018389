import React, { useEffect, useState } from "react";
import { config } from "../config";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import "../scss/create-user.scss";
import Tree from "rc-tree";
import axios from "axios";
import { GroupType, RoleType } from "../types/auth";

const { baseUrl } = config;

type Inputs = {
  username: string;
  password: string;
};

const convertData = (data: (RoleType | GroupType)[]): any => {
  return data.map((item) => ({
    key: item.id,
    title: item.name,
    children: convertData(item.children),
  }));
};

function CreateUser() {
  const access_token = localStorage.getItem("access_token");

  const navigate = useNavigate();

  const [groups, setGroups] = useState<any[]>();
  const [roles, setRoles] = useState<any[]>();

  const [selectedGroups, setSelectedGroups] = useState<number[]>();
  const [selectedRoles, setSelectedRoles] = useState<number[]>();


  const onSelectGroup = (selectedKeys: any[], info: any) => {
    console.log("selected groups: ", selectedKeys);
    setSelectedGroups(selectedKeys);
  };

  const onSelectRole = (selectedKeys: any[], info: any) => {
    console.log("selected roles: ", selectedKeys);
    setSelectedRoles(selectedKeys);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    create(data);
  };

  useEffect(() => {
    console.log(errors);
    setErrorLogin("");
  }, [errors.username, errors.password]);

  const getGroups = () => {
    axios
      .get(`${baseUrl}/groups/hierarchy`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        const data = convertData(response.groups);
        setGroups(data);
        console.log(response);
      })
      .catch((error) => {
        const message = error.response.data.data.message;
        console.log(message);
      });
  };

  const getRoles = () => {
    axios
      .get(`${baseUrl}/roles/hierarchy`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        const data = convertData(response.roles);
        setRoles(data);
        console.log(response);
      })
      .catch((error) => {
        // const message = error.response.data.data.message;
        console.log(error);
      });
  };

  useEffect(() => {
    getRoles();
    getGroups();
  }, []);

  const [errorCreate, setErrorLogin] = useState<string>();

  const create = ({ username, password }: Inputs) => {
    console.log("create user:", {
      username,
      password,
      groups: selectedGroups,
      roles: selectedRoles,
    })

    axios
      .post(`${baseUrl}/users`, {
        username,
        password,
        roles:  selectedGroups,
        groups: selectedRoles,
      }, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        navigate("/admin/user");
        console.log(response);
      })
      .catch((error) => {
        const message = error.response.data.data.message;
        console.log(message);
        setErrorLogin(message.username);
      });
  };

  return (
    <>
      <h1 className="">Создание</h1>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="login__form create-user"
      >
        <div className={`form-field ${errors.username ? "invalid" : ""}`}>
          <div className="form-field__label">Логин</div>
          <input {...register("username", { required: true })} />
          {errors.username && (
            <div className="form-field__error">
              {errors.username.type === "required" &&
                "Это поле обязательно для заполнения"}
            </div>
          )}
        </div>
        <div className={`form-field ${errors.password ? "invalid" : ""}`}>
          <div className="form-field__label">Пароль</div>
          <input {...register("password", { required: true })} />
          {errors.password && (
            <div className="form-field__error">
              {errors.password.type === "required" &&
                "Это поле обязательно для заполнения"}
            </div>
          )}
        </div>
        <div className="form-field">
          <div className="form-field__label">Группы</div>
          <div className="tree__container">
            {groups?.length ? (
              <Tree
                treeData={groups}
                checkable
                onSelect={onSelectGroup}
                defaultExpandAll
                showIcon
                multiple
              />
            ) : (
              <p>нет данных</p>
            )}
          </div>
        </div>
        <div className="form-field">
          <div className="form-field__label">Роли</div>
          <div className="tree__container">
            {roles?.length ? (
              <Tree
                treeData={roles}
                checkable
                onSelect={onSelectRole}
                defaultExpandAll
                showIcon
                multiple
              />
            ) : (
              <p>нет данных</p>
            )}
          </div>
        </div>
        <div>
          {errorCreate && <div className="form-field__error">{errorCreate}</div>}
          <input type="submit" className="btn btn-green" value="Создать" />
        </div>
      </form>
    </>
  );
}

export default CreateUser;
