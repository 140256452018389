import React, { useEffect, useState } from "react";
import { config } from "../config";
import { useNavigate } from "react-router-dom";

import "react-select-search/style.css";
import "../scss/create-user.scss";

import axios from "axios";
import { User } from "./Users";
import SelectSearch, {
  SelectedOptionValue,
  SelectSearchOption,
} from "react-select-search";

const { baseUrl } = config;

function CreateUser() {
  const access_token = localStorage.getItem("access_token");

  const navigate = useNavigate();

  const [users, setUsers] = useState<SelectSearchOption[]>([]);
  const [selectedUser, setSelectedUser] = useState<
    SelectedOptionValue
  >(0);

  const getUsers = () => {
    axios
      .get(`${baseUrl}/users`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        console.log(response);
        setUsers(convertData(response.users));
      })
      .catch((error) => {
        const message = error.response.data.data.message;
        console.log(message);
        setErrorLogin(message.username);
      });
  };

  const convertData = (data: User[]) =>
    data.map((item) => ({
      value: item.username,
      name: item.username,
    }));

  useEffect(() => {
    getUsers();
  }, []);

  const [errorCreate, setErrorLogin] = useState<string>();

  const create = (username: SelectedOptionValue) => {
    axios
      .post(
        `${baseUrl}/device-keys`,
        {
          username,
        },
        {
          headers: {
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((response) => response.data.data)
      .then((response) => {
        navigate("/admin/token");
        console.log(response);
      })
      .catch((error) => {
        const message = error.response.data.data.message;
        console.log(message);
        setErrorLogin(message.username);
      });
  };

  return (
    <>
      <h1 className="">Создание</h1>

      <div className="token__create">
        <SelectSearch
          options={users}
          placeholder="Пользователь"
          search={true}
          onChange={(value) => {
            !Array.isArray(value) && setSelectedUser(value);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
          multiple={false}
        />

          <button className="btn btn-green" onClick={() => {create(selectedUser)}}>Создать</button>
      </div>



    </>
  );
}

export default CreateUser;
