import React, { useEffect, useState } from "react";
import { GroupType, RoleType } from "../types/auth";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import "../scss/tree.scss";
import { config } from "../config";
import axios from "axios";

const { baseUrl } = config;

function Group() {
  const access_token = localStorage.getItem("access_token");
  const [groups, setGroups] = useState<any[]>();
  const [selectedGroup, setSelectedGroup] = useState<{key: number; tille: string;} | null>(null);
  const [selelectedGroupName, setSelelectedGroupName] = useState<string>("");

  const onSelect = (selectedKeys: any, info: any) => {
    console.log("selected", selectedKeys, info);
    setSelectedGroup(selectedKeys[0] && info.node);
    setSelelectedGroupName(selectedKeys[0] ? info.node.title : "");
  };

  const convertData = (data: (RoleType | GroupType)[]): any => {
    return data.map((item) => ({
      key: item.id,
      title: item.name,
      children: convertData(item.children),
    }));
  };

  const editGroup = ({
    id,
    name,
    parent_id,
  }: {
    id: number;
    name?: string;
    parent_id?: number;
  }) => {
    const body: {
      name?: string;
      parent_id?: number;
    } = {};

    name && (body.name = name);
    parent_id && (body.parent_id = parent_id);

    axios
      .put(`${baseUrl}/groups/${id}`, body, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        getGroups();
      })
      .catch((error) => {
        // const message = error.response.data.data.message;
        console.log(error);
      });
  };

  const creatGroup = (groupId?: any) => {
    const body = {
      name: "Новая группа",
    };

    axios
      .post(`${baseUrl}/groups`, body, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        if (groupId) {
          console.log("creatGroup response: ", response);

          editGroup({
            id: response.group.id,
            parent_id: groupId.key,
          });
        } else {
          getGroups();
        }
      })
      .catch((error) => {
        const message = error.response.data.data.message;
        console.log(message);
      });
  };

  const deleteGroup = (group: any) => {
    axios
      .delete(`${baseUrl}/groups/${group.key}`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        getGroups();
        console.log(response);
        setSelectedGroup(null);
        setSelelectedGroupName("");
      })
      .catch((error) => {
        const message = error.response.data.data.message;
        console.log(message);
      });
  };

  const getGroups = () => {
    axios
      .get(`${baseUrl}/groups/hierarchy`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        const data = convertData(response.groups);
        setGroups(data);
        console.log(response);
      })
      .catch((error) => {
        const message = error.response.data.data.message;
        console.log(message);
      });
  };

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <>
      <h1 className="">Группы</h1>

      <div className="tree-bg">
        <div className="tree__buttons">
          <button
            className="btn btn-blue"
            onClick={() => {
              creatGroup();
            }}
          >
            Создать корневую группу
          </button>

          {selectedGroup && (
            <>
              <button
                className="btn btn-blue"
                onClick={() => {
                  creatGroup(selectedGroup);
                }}
              >
                Создать группу
              </button>

              <button
                className="btn btn-blue"
                onClick={() => {
                  deleteGroup(selectedGroup);
                }}
              >
                Удалить
              </button>

              <div className={`form-field`}>
                <input value={selelectedGroupName} onChange={(e) => {
                  setSelelectedGroupName(e.target.value);
                  editGroup({id: selectedGroup.key, name: e.target.value})
                }} onBlur={(e) => {
                  
                }}/>
              </div>
            </>
          )}
        </div>

        <div className="tree__container">
          {groups?.length ? (
            <Tree
              treeData={groups}
              checkable
              onSelect={onSelect}
              defaultExpandAll
              showIcon
            />
          ) : (
            <p>нет данных</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Group;
