import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../config";
import { ErrorResponse, GroupType } from "../types/auth";

const { baseUrl } = config;

const baseQuery = fetchBaseQuery({
  baseUrl: `${baseUrl}/groups`,
  prepareHeaders: (headers) => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      headers.set("authorization", `Bearer ${access_token}`);
    }
    headers.set("Content-Type", "application/json");

    return headers;
  },
});

type GroupResponseSuccess = {
    status: boolean;
    statusText: string;
    statusCode: number;
    data: {
        groups: {
            id: number;
            name: string;
            parent_id: number;
        }[];
    };
}

type GroupTreeResponseSuccess = {
    status: boolean;
    statusText: string;
    statusCode: number;
    data: {
        groups: GroupType[];
    };
}

type CreatGroupResponseSuccess = {
    status: boolean;
    statusText: string;
    statusCode: number;
    data: {
        group: {
            id: number;
            name: string;
            parent_id?: number;
        };
    };
}

type CreateGroupBody = {
    name: string;
}

export const groupApi = createApi({
  reducerPath: "groupApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getGroups: builder.mutation<CreatGroupResponseSuccess, void>({
      query: () => ({
        url: "",
        method: "GET",
      }),
    }),
    getGroupsTree: builder.mutation<GroupTreeResponseSuccess, void>({
      query: () => ({
        url: "/hierarchy",
        method: "GET",
      }),
    }),
    createGroup: builder.mutation<CreatGroupResponseSuccess, CreateGroupBody>({
        query: (body) => ({
          url: "/hierarchy",
          method: "POST",
          body,
        }),
      }),
  }),
});

export const { useGetGroupsMutation, useGetGroupsTreeMutation, useCreateGroupMutation } = groupApi;
