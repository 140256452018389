import React, { useEffect, useState } from "react";
import { config } from "../config";
import { useLocation, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import "../scss/create-user.scss";
import Tree from "rc-tree";
import axios from "axios";
import { GroupType, RoleType } from "../types/auth";

const { baseUrl } = config;

type Inputs = {
  username: string;
  password: string;
};

const convertData = (data: (RoleType | GroupType)[]): any => {
  return data.map((item) => ({
    key: item.id,
    title: item.name,
    children: convertData(item.children),
  }));
};

function UpdataUser() {
  const access_token = localStorage.getItem("access_token");

  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = state;

  const [groups, setGroups] = useState<any[]>();
  const [roles, setRoles] = useState<any[]>();

  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);

  const onSelectGroup = (selectedKeys: any[]) => {
    console.log("selected groups: ", selectedKeys);
    setSelectedGroups(selectedKeys);
  };

  const onSelectRole = (selectedKeys: any[]) => {
    console.log("selected roles: ", selectedKeys);
    setSelectedRoles(selectedKeys);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    save(data);
  };

  useEffect(() => {
    console.log(errors);
    setErrorLogin("");
  }, [errors.username, errors.password]);

  const getGroups = () => {
    axios
      .get(`${baseUrl}/groups/hierarchy`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        const data = convertData(response.groups);
        setGroups(data);
      })
      .catch((error) => {
        const message = error.response.data.data.message;
        console.log(message);
      });
  };

  const getRoles = () => {
    axios
      .get(`${baseUrl}/roles/hierarchy`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        const data = convertData(response.roles);
        setRoles(data);
      })
      .catch((error) => {
        // const message = error.response.data.data.message;
        console.log(error);
      });
  };

  const selectRoles = () => {
    const idList: number[] = [];

    function traverse(roles: RoleType[]) {
      roles.forEach((role) => {
        idList.push(role.id);
        if (role.children && role.children.length > 0) {
          traverse(role.children);
        }
      });
    }

    console.log("selectRoles: ", idList);


    traverse(user.roles);
    setSelectedRoles(idList);
  };

  const selectGroups = () => {
    const idList: number[] = [];

    function traverse(groups: GroupType[]) {
      groups.forEach((group) => {
        idList.push(group.id);
        if (group.children && group.children.length > 0) {
          traverse(group.children);
        }
      });
    }

    console.log("selectGroups: ", idList);

    traverse(user.groups);
    setSelectedGroups(idList);
  };

  useEffect(() => {
    getRoles();
    getGroups();

    setValue("username", user.username);

    selectRoles();
    selectGroups();
  }, []);

  const [errorCreate, setErrorLogin] = useState<string>();

  const save = ({ username, password }: Inputs) => {
    console.log({
      username,
      password,
      status: "active",
      roles: selectedRoles,
      groups: selectedGroups,
    });
      
    axios
      .put(`${baseUrl}/users/${user.id}`, {
        username,
        password,
        status: "active",
        roles: selectedRoles,
        groups: selectedGroups,
      }, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        navigate("/admin/user");
        console.log(response);
      })
      .catch((error) => {
        const message = error.response.data.data.message;
        console.log(message);
        setErrorLogin(message.username);
      });
  };

  return (
    <>
      <h1 className="">Изменение: {user.username}</h1>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="login__form create-user"
      >
        <div className={`form-field ${errors.username ? "invalid" : ""}`}>
          <div className="form-field__label">Логин</div>
          <input {...register("username", { required: true })}/>
          {errors.username && (
            <div className="form-field__error">
              {errors.username.type === "required" &&
                "Это поле обязательно для заполнения"}
            </div>
          )}
        </div>
        <div className={`form-field ${errors.password ? "invalid" : ""}`}>
          <div className="form-field__label">Пароль</div>
          <input {...register("password")} />
          {errors.password && (
            <div className="form-field__error">
              {errors.password.type === "required" &&
                "Это поле обязательно для заполнения"}
            </div>
          )}
        </div>
        <div className="form-field">
          <div className="form-field__label">Группы</div>
          <div className="tree__container">
            {groups?.length ? (
              <Tree
                treeData={groups}
                checkable
                onSelect={onSelectGroup}
                defaultExpandAll
                showIcon
                multiple
                selectedKeys={[...selectedGroups]}
              />
            ) : (
              <p>нет данных</p>
            )}
          </div>
        </div>
        <div className="form-field">
          <div className="form-field__label">Роли</div>
          <div className="tree__container">
            {roles?.length ? (
              <Tree
                treeData={roles}
                checkable
                onSelect={onSelectRole}
                defaultExpandAll
                showIcon
                multiple
                selectedKeys={[...selectedRoles]}
              />
            ) : (
              <p>нет данных</p>
            )}
          </div>
        </div>
        <div>
          {errorCreate && <div className="form-field__error">{errorCreate}</div>}
          <input type="submit" className="btn btn-green" value="Сохранить" />
        </div>
      </form>
    </>
  );
}

export default UpdataUser;
